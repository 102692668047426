@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    footer a {
      @apply no-underline hover:underline;
    }
}


@layer components {
  .hero {
    background-image: linear-gradient(60deg, #d8dbe5 0, #f1f1f1 80%, #d8dbe5);
  }

  cite {
    position: relative;
    text-decoration: underline;
    display: inline-flex;
    font-size: 1rem;
    transform: translateY(-4px);
    color: theme("colors.primary.500")
  }

  cite a {
    color: inherit;
  }

  @media screen and (min-width: 1025px) {
    .hero {
      background-image: linear-gradient(60deg, #d8dbe5 0, #f1f1f1 30%, #98b5fe);
    }
  }
}
